import cookie from 'cookie';

declare global {
  interface Window {
    dataLayer: any;
    google_tag_manager: any;
  }
}

export interface GTMPage {
  page_type: 'Formation' | 'Ressource' | 'Communauté' | 'Commun' | 'Profil';

  [key: `page_level${number}`]: string;
}

interface ISendToGTM {
  data: Record<string, any> & {
    event: string,
    page?: GTMPage
  };
}

export const useGoogleTagManager = () => {
  function sendDataToGTM({ data }: ISendToGTM): void {
    if (typeof window === 'undefined' || !window?.tarteaucitron) {
      return;
    }
    const cookies = cookie.parse(window.tarteaucitron.cookie.read() || '');
    if (cookies['!googletagmanager'] !== 'true') {
      return;
    }

    window.dataLayer = window.dataLayer || [];
    // we limit the dataLayer to 30 elements and clean after because we never reload the page manually
    if (window.dataLayer.length > 30) {
      cleanDataLayer();
    }
    window.dataLayer.push(data);
  }

  // Resetting the dataLayer on Google Tag Manager
  // https://trackingchef.com/google-tag-manager/resetting-the-data-layer-in-google-tag-manager/
  // https://stackoverflow.com/questions/66172573/gtm-does-not-reset-datalayer-on-page-navigation
  function cleanDataLayer() {
    if (window.dataLayer !== undefined && window.google_tag_manager !== undefined) {
      window.dataLayer.length = 0;
      const gtmContainerReg = /GTM-/i;

      for (const gtmKey of Object.keys(window.google_tag_manager)) {
        if (gtmContainerReg.test(gtmKey) && window.google_tag_manager[gtmKey].dataLayer
          && window.google_tag_manager[gtmKey].dataLayer.reset
        ) {
          window.google_tag_manager[gtmKey].dataLayer.reset();
        }
      }
    }

  }

  return { sendDataToGTM };
};
